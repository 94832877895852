import { useEffect, useState } from 'react';
import { useNextContextConsumer } from 'contexts/NextContext';

import ShippingSection from 'components/common/form/components/ShippingSection';
import PersonalInfoSection from 'components/common/form/components/PersonalInfoSection';
import GeneralSection from '../components/GeneralSection';
import BankSection from '../components/BankSection';
import KRPhoneValidationSection from '../components/KRPhoneValidationSection';
import useValidationConsolidator from 'hooks/useValidationConsolidator';
import envConfig from 'config';
import { inputState } from 'state';
import { useRecoilValue } from 'recoil';

const parseLocaleData = ({ fields: o = {} }) =>
  [
    { title: o.text5, list: [], sectionList: [] },
    { title: o.buttonText2, list: [], sectionList: [], krTitle: '' },
    { title: o.text6, list: [], sectionList: [], shippingInformation: o.text7 },
    { title: o.buttonText4, list: [], sectionList: [] },
    { title: o.text10, list: [], sectionList: [o.buttonText1] },
    { title: o.description, list: [], sectionList: [] },
    { title: o.text8, list: [o.text9, o.buttonText3, o.text11], sectionList: [] },
    { title: o.text13, list: [], sectionList: [] },
  ]

function DefaultForm(props) {
  const { next, setNext } = useNextContextConsumer();
  const [formStatus, set_formStatus] = useState('invalid');
  const { config, data, ...rest } = props;
  const parsedData = parseLocaleData(data);

  let sections = [];
  Object.keys(config.enrollment.form.sections)
    .map((sectionName) => {
      return {
        ...config.enrollment.form.sections[sectionName],
        name: sectionName,
      };
    })
    .forEach((sectionObj) => {
      const { order } = sectionObj;
      sections[order] = sectionObj;
    })

  sections = sections.filter(o => !!o)

  const inputValues = useRecoilValue(inputState);
  const validationArray = useValidationConsolidator(
    sections.map(({ name }) => name),
    (boolean) => {
      set_formStatus(boolean ? 'complete' : 'invalid');
    },
  );

  useEffect(() => {
    /**
     * formStatus
     * invalid - next button disabled
     * complete - next button enabled
     * validating - triggers modals on child
     *
     */
    if (formStatus === 'complete') {
      setNext({ ...next, enabled: true, validate: onNextButton });
    } else {
      setNext({ ...next, enabled: false });
    }
  }, [formStatus]);

  async function onNextButton() {
    set_formStatus('validating');
    return {};
  }
  return sections.map((section, index) => {
    const { name } = section;

    const sectionProps = {
      ...rest,
      config,
      name,
      key: name,
      isValid: validationArray[index],
      formStatus: { status: formStatus, setStatus: set_formStatus },
      data: parsedData[section.localeEntry || index],
      defaultForm: inputValues,
    };
    if (name === 'shipping') {
      return <ShippingSection {...sectionProps} />;
    }
    if (name === 'personal') {
      return <PersonalInfoSection {...sectionProps} />;
    }
    if (name === 'bank') {
      return <BankSection {...sectionProps} />;
    }
    if (name === 'kr_phone_validation' && envConfig.FEATURE_FLAG_PHONE_VALIDATION) {
      return <KRPhoneValidationSection {...sectionProps} />
    }
    if (name === 'terms') {
      return <GeneralSection {...sectionProps} isTerms />;
    }
    return <GeneralSection {...sectionProps} />;
  });
}

// There is another model (an array) in contentful that if we translate it will be easier to add section data
// function parseLocaleDataArray({ fields: { listArray } = {} }) {
//   if (!listArray) return {};
//   return listArray.map((contentfulObj) => {
//     const { fields: { title, description, item2 } = {} } = contentfulObj;
//     const sectionList = markupToArray(description);
//     const list = markupToArray(item2);
//     return { title, sectionList, list };
//   });
// }

export default DefaultForm;
